import logo from "./assets/dpd3_logo3.png"
import "./App.css"

function App() {
  return (
    <div className='App'>
      <div className='header'>
        <img src={logo} alt='Dermpath Disease Differential Diagnosis' />
      </div>
      <div className='banner'>Coming Soon!</div>
    </div>
  )
}

export default App
